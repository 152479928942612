// Import React and CSS module
import React from 'react';
import styles from '../styles/Home.module.css';

// Import the image
import awsImage from '../images/aws-data-engineering.jpg';
import pythonCourseImage from '../images/python-crash-course.png';
import LinkedInImage from '../images/linkedin-newsletter.png';

const Home = () => (
  <div className={styles.container}>
    <h1 className={styles.title}>AWS Data Engineering Certification Guide</h1>
    <div>
    <p>Welcome to your guide on AWS Data Engineering certification. This certification validates expertise in designing and implementing AWS services to derive value from data. It covers a broad range of topics including data collection, storage, processing, analysis, visualization, and security on the AWS platform.</p>
    <p>To excel in this certification, a comprehensive understanding of services like Amazon S3, AWS Glue, Amazon Redshift, Amazon Kinesis, and AWS Lambda is essential. This guide aims to equip you with the knowledge and skills required to become a certified AWS Data Engineer.</p>
    </div>
    {/*new container */}
    <div className={styles.courseSection}>
    <h1>Why AWS Data Engineering?</h1>
    <p>As organizations continue to generate and collect vast amounts of data, the demand for skilled professionals who can manage, process, and analyze this data is on the rise. AWS is a leading cloud platform that offers a wide array of data engineering services, making it a popular choice for organizations looking to harness the power of their data.</p>
    <p>By becoming an AWS Certified Data Engineer, you can demonstrate your ability to design, build, secure, and maintain analytics solutions on AWS. This certification can open up new career opportunities and help you stand out in the competitive field of data engineering.</p>
    <h2>Join us next Thursday at 7 PM EST for a session on how to prepare for the AWS Data Engineering Certification</h2>

    <p><a href="https://us04web.zoom.us/j/71384238323?pwd=2mDibp6bdCrGCsEAFBBJGtS1aSyCt0.1" target="_blank" rel="noopener noreferrer" className={styles.courseLink}>Join Zoom Meeting</a></p>


    </div>


    {/* LinkedIn AWS Data Engineering Section */}
    <div>
    <p>Join our LinkedIn community to stay updated on the latest AWS Data Engineering trends, news, and resources. Network with other professionals, share your insights, and learn from the experiences of others.</p>
    <p><a href="https://www.linkedin.com/newsletters/data-engineering-with-aws-7096111313352880128/" target="_blank" rel="noopener noreferrer" className={styles.courseLink}>Join our LinkedIn Community</a></p>
    <img src={LinkedInImage} alt="Linkedin Newsletter" className={styles.courseImage}/>

    </div>
    {/* Python Crash Course Section */}
    <div className={styles.courseSection}>
      <h2 className={styles.courseTitle}>Free Python Crash Course</h2>
      <img src={pythonCourseImage} alt="Python Crash Course" className={styles.courseImage}/>
      <p>Jumpstart your programming journey with our free Python crash course. This course is designed for beginners and covers the fundamentals of Python, one of the most popular programming languages in the world.</p>
      <a href="https://akrish82.gumroad.com/l/python-crash-course" target="_blank" rel="noopener noreferrer" className={styles.courseLink}>Access the Free Course</a>
    </div>
    {/* AWS Data Engineering Section */}

    <div>
    <img src={awsImage} alt="AWS Data Engineering" className={styles.image}/>
    </div>
    <p>Take up the AWS Data Engineering Certification with the best test to help to you prepare ...</p>
    <a href="https://www.udemy.com/course/aws-certified-data-engineer-associate-practice-test/?couponCode=LETSLEARNNOWPP#reviews" target="_blank" rel="noopener noreferrer" className={styles.courseLink}>Enroll in our Udemy course for in-depth preparation!</a>
  </div>
);

export default Home;
